import React from "react";
import logo from "./assets/logo.png";
import ReactRotatingText from 'react-rotating-text';

export function Header() {
    return (
        <div className="timezones-header">
            <div className="timezones-header-logo">
                {/* eslint-disable-next-line jsx-a11y/alt-text */}
                <img src={logo} alt=""/>Timezones.
            </div>
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <div className="timezones-header-text large">
                🤔 Is 10am my time OK for Joe in <ReactRotatingText items={['Paris?', 'Dubai?', 'Tokyo?', 'Cairo?']} pause={2500}/>
            </div>
            <div className="timezones-header-text small">
                Enter your remote team member locations <span role="img" aria-label="bellow">👇</span> to start scheduling meetings!
            </div>
        </div>
    )
}