import React, {useState} from 'react';
import CircularProgress from "@material-ui/core/CircularProgress";

const url = 'https://timezones.inviited.com/api/subscribe';
const register = email => fetch(`${url}?email=${email}`, {
    method: 'POST',
    headers: {'Authorization': 'bbk2vwKe3lsR4dra'},
    body: JSON.stringify({email})
});

export default function Subscribe() {
    let [email, setEmail] = useState('');
    let [status, setStatus] = useState('');

    let registerUser = async email => {
        try {
            setStatus('loading');
            let res = await register(email);
            let json = await res.json();
            console.log(json);
            if (res.status === 200) {
                setStatus('success');
            } else {
                setStatus('error');
            }
        } catch (e) {
            console.log(e);
            setStatus('error');
        }
    };

    let isLoading = status === 'loading';
    let isError = status === 'error';
    let isSuccess = status === 'success';

    return (
        <div className="subscribe">
            {isSuccess
                ? <React.Fragment>Thank you for subscribing</React.Fragment>
                : <React.Fragment>
                    Subscribe to be notified of new features!
                    <input
                        className="subscribe-email"
                        type="email"
                        name="email"
                        value={email}
                        placeholder="Enter your email"
                        onChange={event => setEmail(event.target.value)}
                    />
                    <div>
                        <button className="subscribe-button" onClick={() => registerUser(email)}>
                            {isLoading && <CircularProgress color="inherit" size={14}/>} ✉️ Subscribe Now!
                        </button>
                        {isError && <p className="subscribe-error">Subscribe failed, try again</p>}
                    </div>
                </React.Fragment>
            }
        </div>
    )
}