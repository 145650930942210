import React, {useState} from 'react';
import './App.css';
import 'rc-slider/assets/index.css';
import moment from 'moment-timezone';
import SchedulingInfo from "./SchedulingInfo";
import TimezonesSlider from "./TimezonesSlider";
import {currLocation, defaultLocations} from "./TimezoneHelpers";
import {Header} from "./Header";
import {Footer} from "./Footer";
import {createMuiTheme} from '@material-ui/core/styles';
import {ThemeProvider} from "@material-ui/styles";
import Subscribe from "./Subscribe";

const theme = createMuiTheme({
    palette: {
        primary: {500: '#FC8DA3'},
    }
});

function App() {
    let [locations, updateLocations] = useState(defaultLocations);
    let [date, setDate] = useState(moment().startOf('day').hour(12));
    let [time, setTime] = useState(date);
    let [duration, setDuration] = useState(60);

    const addLocation = location => updateLocations([...locations, location]);
    const removeLocation = location => updateLocations(locations.filter(item => item.name !== location.name));
    const handleSetDate = date => {
        setDate(date);
        setTime(date);
    };

    return (

        <ThemeProvider theme={theme}>
            <div className="app">
                <Subscribe/>

                <Header/>

                <SchedulingInfo
                    date={date}
                    setDate={handleSetDate}
                    time={time}
                    setTime={setTime}
                    duration={duration}
                    setDuration={setDuration}/>

                <TimezonesSlider
                    date={date}
                    setDate={setDate}
                    time={time}
                    setTime={setTime}
                    duration={duration}
                    currLocation={currLocation}
                    locations={locations}
                    addLocation={addLocation}
                    removeLocation={removeLocation}/>

                <Footer/>
            </div>
        </ThemeProvider>
    );
}

export default App;
