const moment = require('moment-timezone');
const ct = require('countries-and-timezones');

const timeToDistance = (hours, minutes) => (hours * 4 + minutes / 15) * 100 / 96;
const momentToDistance = moment => timeToDistance(moment.hour(), moment.minutes());

const createTimezoneDay = (date, location) => {
    let dateTz = moment.tz(date, location.timezone).startOf('day');
    return {
        location,
        date: dateTz,
        sections: [
            {
                start: moment(date).hour(0),
                end: moment(date).hour(7),
                name: 'start-of-day'
            },
            {
                start: moment(date).hour(7),
                end: moment(date).hour(9),
                name: 'before-work-hours'
            },
            {
                start: moment(date).hour(9),
                end: moment(date).hour(18),
                name: 'work-hours'
            },
            {
                start: moment(date).hour(18),
                end: moment(date).hour(20),
                name: 'after-work-hours'
            },
            {
                start: moment(date).hour(20),
                end: moment(date).hour(24),
                name: 'end-of-day'
            }
        ]
    };
};

const timeZoneDiff = (timezone1, timezone2) => {
    let now = moment.utc();
    let offset1 = moment.tz.zone(timezone1).utcOffset(now);
    let offset2 = moment.tz.zone(timezone2).utcOffset(now);
    return offset2 - offset1;
};

const transformTimezoneDay = (timezoneDay, location) => {
    let diff = timeZoneDiff(timezoneDay.location.timezone, location.timezone);
    let sections = [];
    let startOfDay = timezoneDay.date;
    let endOfDay = moment(timezoneDay.date).add(1, 'day');

    timezoneDay.sections.forEach(section => {
        // move section for the time difference
        let newSection = {
            ...section,
            start: moment(section.start).add(diff, 'minutes'),
            end: moment(section.end).add(diff, 'minutes')
        };

        if (startOfDay.isAfter(newSection.start) && startOfDay.isBefore(newSection.end)) {
            // if section overlaps with start of today break down in two sections
            let secondSectionStart = moment(newSection.start).add(1, 'day');
            // first section starts from midnight
            newSection.start = moment(startOfDay);
            newSection.split = true;
            // second section
            let secondSection = {
                ...newSection,
                start: secondSectionStart,
                end: moment(endOfDay),
                split: true
            };
            sections.push(secondSection)
        } else if (endOfDay.isAfter(newSection.start) && endOfDay.isBefore(newSection.end)) {
            // if section overlaps with end of today break down in two sections
            let secondSectionEnd = moment(newSection.end).subtract(1, 'day');
            // first section starts from midnight
            newSection.end = moment(endOfDay);
            newSection.split = true;
            // second section
            let secondSection = {
                ...newSection,
                start: moment(startOfDay),
                end: secondSectionEnd,
                split: true
            };
            sections.push(secondSection)
        } else if (startOfDay.isSameOrAfter(newSection.end)) {
            // if section is in previous day move to next (add a day)
            newSection.start.add(1, 'day');
            newSection.end.add(1, 'day');
        } else if (endOfDay.isSameOrBefore(newSection.end)) {
            // if section is in next day move to previous (deduct a day)
            newSection.start.subtract(1, 'day');
            newSection.end.subtract(1, 'day');
        }
        sections.push(newSection)
    });

    // sort sections
    sections.sort((a, b) => (a.start.isAfter(b.start)) ? 1 : -1);
    return {location, date: timezoneDay.date, sections}
};

function addUiProperties(timezoneDay) {
    let {sections, location} = timezoneDay;
    let {timezone} = location;

    sections.forEach((section, index) => {

        section.weekday = moment.tz(section.start, timezone).format('dddd');
        section.dayOfMonth = moment.tz(section.start, timezone).format('Do');

        let start = momentToDistance(section.start);
        let end = momentToDistance(moment(section.end).subtract(1, 'millisecond'));
        let width = end - start;
        section.dimens = {start, end, width};

        switch (section.name) {
            case 'start-of-day':
                if (!section.split && index === 0) section.textLeft = {text: '12:01am', style: 'dark'};
                if (index !== 5) section.textRight = {text: '7am', style: 'dark'};
                break;
            case 'before-work-hours':
                if (index !== 0) section.rounded = true;
                if (index === 5) section.textCenter = {text: section.weekday.substr(0, 3), style: 'bold'};
                break;
            case 'work-hours':
                section.textCenter = {
                    text: (section.split || isSmallScreen()) ? section.weekday.substr(0, 3) : section.weekday,
                    style: 'bold'
                };
                if (index !== 0) section.textLeft = {text: '9am', style: 'light'};
                if (index !== 5) section.textRight = {text: '6pm', style: 'light'};
                break;
            case 'after-work-hours':
                if (index !== 5) section.rounded = true;
                if (index === 0) section.textCenter = {text: section.weekday.substr(0, 3), style: 'bold'};
                break;
            case 'end-of-day':
                if (!section.split && index === 4 && sections.length === 5)
                    section.textRight = {text: '11:59pm', style: 'dark'};
                section.textLeft = {text: '8pm', style: 'dark'};
                break;
            default:
                break;

        }
    });
}

const isSmallScreen = () =>  window.innerWidth <= 512;

export const createTimezonesDayInfo = (date, currLocation, locations) => {
    let result = [];

    let currTimezoneDay = createTimezoneDay(date, currLocation);
    result.push(currTimezoneDay);

    let otherTimezonesDays = locations.map(location => transformTimezoneDay(currTimezoneDay, location));
    result.push(...otherTimezonesDays);

    result.forEach(timezoneDay => addUiProperties(timezoneDay));

    return result
};

const sliderValueToTimeString = (sliderValue, date, timezone) => {
    return sliderValueToTime(sliderValue, date, timezone).format('hh:mm a')
};

export const sliderValueToTime = (sliderValue, date, timezone) => {
    return moment(date).startOf('day').minutes(sliderValue * 5).tz(timezone);
};

export const timeToSliderValue = (time, timezone, duration) => {
    let tzTime = moment.tz(time, timezone);
    let totalMinutes = tzTime.hour() * 60 + tzTime.minutes() + duration / 2;
    return totalMinutes / 5
};

export const createTimezonesSliderInfo = (date, duration, tzCount) => {
    let height = tzCount * 60;

    let startTime = (sliderValue, timezone) => {
        let startValue = sliderValue - duration / 5 / 2;
        return sliderValueToTimeString(startValue, date, timezone)
    };

    let endTime = (sliderValue, timezone) => {
        let endValue = sliderValue + duration / 5 / 2;
        return sliderValueToTimeString(endValue, date, timezone)
    };

    return {
        min: 0,
        max: 288,
        width: duration / 5 * 100 / 288,
        height,
        handleHeight: height + 2 * 20,
        handleMarginTop: -20,
        startTime,
        endTime
    }
};

export const timezoneName = timezone => (timezone.includes('/') ? timezone.split('/')[1] : timezone).replace(/_/g, " ");
export const getCountryIdForTimezone = timezone => ct.getCountryForTimezone(timezone).id;

const currTimezone = moment.tz.guess();
export const currLocation =
    {
        name: timezoneName(currTimezone),
        displayName: timezoneName(currTimezone),
        timezone: currTimezone,
        country: getCountryIdForTimezone(currTimezone)
    };
export const defaultLocations = [
    {name: 'San Francisco', displayName: 'San Francisco', timezone: 'America/Los_Angeles', country: 'us'},
    {name: 'Berlin', displayName: 'Berlin', timezone: 'Europe/Berlin', country: 'de'}
];
