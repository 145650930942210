import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import React from "react";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import {getTimezoneForLocation, loadGoogleMaps} from "./Helpers";
import throttle from "lodash/throttle";
import parse from "autosuggest-highlight/parse";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Dialog from "@material-ui/core/Dialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {getCountryIdForTimezone} from "./TimezoneHelpers";
import LocationOnIcon from '@material-ui/icons/LocationOn';

// const autocompleteService = {current: null};
const googleService = {maps: null};

export default function AddTimezone({addLocation}) {

    const [open, setOpen] = React.useState(false);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState([]);
    const [placeValue, setPlaceValue] = React.useState(null);
    const [loading, setLoading] = React.useState(null);

    const loaded = React.useRef(false);
    loadGoogleMaps(loaded);

    const handleClose = () => setOpen(false);
    const handleAddTimezone = async () => {
        setLoading(true);
        let loc = await getAddress(placeValue);
        let timezone = (await getTimezoneForLocation(loc.lat(), loc.lng())).timeZoneId;
        setLoading(false);
        setOpen(false);
        let displayName = placeValue.split(',')[0].trim();
        addLocation({name: placeValue, displayName, timezone, country: getCountryIdForTimezone(timezone)})
    };
    const handleInputChange = event => setInputValue(event.target.value);
    const handlePlaceChange = (event, value) => setPlaceValue(value && value.description);

    const fetch = React.useMemo(
        () =>
            throttle((request, callback) => {
                googleService.autocomplete.getPlacePredictions(request, callback);
            }, 200),
        [],
    );

    React.useEffect(() => {
        let active = true;

        if (!googleService.current && window.google) {
            googleService.current = window.google.maps;
            googleService.autocomplete = new window.google.maps.places.AutocompleteService();
            googleService.geocoder = new window.google.maps.Geocoder();
        }

        if (!googleService.current) {
            return undefined;
        }

        if (inputValue === '') {
            setOptions([]);
            return undefined;
        }

        fetch({input: inputValue, types: ['geocode']}, results => {
            if (active) {
                setOptions(results || []);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, fetch]);

    return (
        <div>
            <button
                className="timezones-button add"
                onClick={() => setOpen(true)}>
                + Add city
            </button>
            <Dialog
                open={open}
                // onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <DialogTitle>Search for places</DialogTitle>
                <DialogContent>
                    <DialogContentText>Start typing city name</DialogContentText>
                    <Autocomplete
                        options={options}
                        getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                        style={{width: 300}}
                        disableOpenOnFocus
                        onChange={handlePlaceChange}
                        renderInput={params => <TextField autoFocus {...params} onChange={handleInputChange}/>}
                        renderOption={option => {
                            const matches = option.structured_formatting.main_text_matched_substrings;
                            const parts = parse(
                                option.structured_formatting.main_text,
                                matches.map(match => [match.offset, match.offset + match.length]),
                            );
                            return (
                                <Grid container alignItems="center">
                                    <Grid item>
                                        <LocationOnIcon color="primary" style={{marginRight: '12px'}} />
                                    </Grid>
                                    <Grid item xs>
                                        {parts.map((part, index) => (
                                            <span key={index}
                                                  style={{fontWeight: part.highlight ? 700 : 400}}>
                                                {part.text}
                                            </span>
                                        ))}

                                        <Typography variant="body2" color="textSecondary">
                                            {option.structured_formatting.secondary_text}
                                        </Typography>
                                    </Grid>
                                </Grid>
                            );
                        }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAddTimezone} color="primary" disabled={placeValue === null || loading}>
                        {loading && <CircularProgress size={14}/>}
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

const getAddress = address => {
    return new Promise((resolve, reject) => {
        googleService.geocoder.geocode({address: address}, (results, status) => {
            if (status === 'OK') {
                resolve(results[0].geometry.location);
            } else {
                reject(status);
            }
        });
    });
};
