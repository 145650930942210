import React from "react";
export function Footer() {
    return (
        <div className="timezones-footer">
            {/* eslint-disable-next-line jsx-a11y/accessible-emoji */}
            <div className="timezones-footer-text large">
                Made with ❤️ in Melbourne
            </div>
            <div className="timezones-footer-text small">
                ©Sugar Rush Apps Pty Ltd.
            </div>
        </div>
    )
}