import DateFnsUtils from "@date-io/date-fns";
import {KeyboardDatePicker, KeyboardTimePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import AddToCalendar from "./add-to-calendar/ReactAddToCalendar";
import React from "react";
import moment from "moment-timezone";
import {TimeIcon} from "@material-ui/pickers/_shared/icons/TimeIcon";
import TextField from "@material-ui/core/TextField";


const DateTextFieldComponent = (props) => {
    props.InputProps.readOnly = true;
    let date = moment(props.value).format("ddd, DD MMM");
    return <TextField {...props} value={date}/>
};

export default function SchedulingInfo({date, setDate, time, setTime, duration, setDuration}) {

    const event = {
        description: '\n\n\n\n\nThis meeting was made possible by Timezones \nhttps://timezones.inviited.com',
        startTime: moment(time).format(),
        endTime: moment(time).add(duration, 'minutes').format()
    };

    return (
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <div className="scheduling-info-container">
                <div className="scheduling-info-item">
                    <div className="scheduling-info-text">Let's meet</div>
                    <KeyboardDatePicker
                        className="scheduling-info-input date"
                        format="yyyy-MM-dd"
                        margin="normal"
                        id="date-picker-inline"
                        value={date}
                        onChange={setDate}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                            color: 'primary'
                        }}
                        TextFieldComponent={DateTextFieldComponent}
                    />
                </div>
                <div className="scheduling-info-item">
                    <div className="scheduling-info-text">at</div>
                    <KeyboardTimePicker
                        className="scheduling-info-input time"
                        margin="normal"
                        id="time-picker"
                        value={time}
                        onChange={setTime}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                            color: 'primary'
                        }}
                        keyboardIcon={<TimeIcon />}
                    />
                </div>
                <div className="scheduling-info-item">
                    <div className="scheduling-info-text">for</div>
                    <Select
                        className="scheduling-info-input duration"
                        value={duration}
                        onChange={event => setDuration(event.target.value)}>
                        <MenuItem value={30}>30 mins</MenuItem>
                        <MenuItem value={60}>1 hour</MenuItem>
                        <MenuItem value={90}>1.5 hour</MenuItem>
                        <MenuItem value={120}>2 hours</MenuItem>
                    </Select>
                </div>
                <div className="scheduling-info-item">
                    <AddToCalendar
                        event={event}
                        buttonLabel="&nbsp;🚀&nbsp;&nbsp;&nbsp;Schedule Meeting">
                    </AddToCalendar>
                </div>
            </div>
        </MuiPickersUtilsProvider>
    )

}