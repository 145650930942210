import React from "react";
import {
    createTimezonesDayInfo,
    createTimezonesSliderInfo,
    sliderValueToTime,
    timeToSliderValue
} from "./TimezoneHelpers";
import ReactCountryFlag from "react-country-flag"
import Slider from "rc-slider";
import Handle from "./Handle";
import AddTimezone from "./AddTimezone";
import moment from "moment";

const handle = (timezones, sliderInfo) => props => {
    const {value, dragging, index, ...restProps} = props;
    return (
        <Handle value={value} {...restProps} timezones={timezones} sliderInfo={sliderInfo}
                style={{
                    width: `${sliderInfo.width}%`,
                    height: `${sliderInfo.handleHeight}px`,
                    marginTop: `${sliderInfo.handleMarginTop}px`,
                    borderRadius: '0px',
                    background: 'rgba(252, 141, 163, .4)'
                }}/>
    );
};

export default function TimezonesSlider({date, setDate, time, setTime, duration, currLocation, locations, addLocation, removeLocation}) {
    let timezonesDayInfo = createTimezonesDayInfo(date, currLocation, locations);
    let timezonesSliderInfo = createTimezonesSliderInfo(date, duration, locations.length + 1);
    let currTimezone = currLocation.timezone;
    let allTimezones = [currTimezone, ...locations.map(location => location.timezone)];
    let prevDate = moment(date).subtract(1, 'day');
    let nextDate = moment(date).add(1, 'day');

    return (
        <div className="timezones-container noselect">
            <div className="timezones-info-container">
                {timezonesDayInfo.map((timezoneDay, index) =>
                    <TimezoneInfo
                        key={timezoneDay.location.name}
                        location={timezoneDay.location}
                        country={timezoneDay.country}
                        remove={index !== 0 ? removeLocation : undefined}
                    />
                )}
                <AddTimezone addLocation={addLocation}/>
            </div>
            <div className="timezones-slider-container">
                <div className="timezones-day-container">
                    {timezonesDayInfo.map((timezoneDay, index) =>
                        <TimezoneDay
                            key={`${timezoneDay.country}-${timezoneDay.location.name}`}
                            sections={timezoneDay.sections}
                            border={index === 0}
                        />
                    )}
                </div>
                <button className="timezones-button date previous" onClick={() => setDate(prevDate)}>
                    ← {prevDate.format("ddd, DD MMM")}
                </button>
                <button className="timezones-button date next" onClick={() => setDate(nextDate)}>
                    {nextDate.format("ddd, DD MMM")} →
                </button>
                <Slider
                    min={timezonesSliderInfo.min}
                    max={timezonesSliderInfo.max}
                    value={timeToSliderValue(time, currTimezone, duration)}
                    trackStyle={{opacity: 0, height: 0}}
                    railStyle={{opacity: 0, height: timezonesSliderInfo.height}}
                    handle={handle(allTimezones, timezonesSliderInfo)}
                    onChange={value => setTime(sliderValueToTime(value - duration / 5 / 2, date, currTimezone))}
                />
            </div>
        </div>
    )
}

function TimezoneDay({sections, border}) {
    return (
        <div className="timezone-day">
            {border && <div className="timezone-day-border"/>}
            <div className="timezone-day-sections-container">
                {sections.map((section, index) => <TimezoneDaySection key={`${index}-${section.name}`} section={section}/>)}
                <div className="timezone-day-border"/>
            </div>
            {border && <div className="timezone-day-border"/>}
        </div>
    )
}

function TimezoneInfo(props) {
    let {location, remove} = props;
    let {displayName, country} = location;
    return (
        <div className="timezone-info">
            {remove
                ? <button className="timezones-button remove" onClick={() => remove(location)}>-</button>
                : <div className="timezones-home-icon"/>
            }
            <div className="timezone-flag"><ReactCountryFlag countryCode={country} svg/></div>
            <div className="timezone-name">{displayName}</div>
        </div>
    )
}

function TimezoneDaySection(props) {
    let {name, rounded, dimens, textLeft, textCenter, textRight, textColor} = props.section;

    let sectionClass = `timezone-day-section ${name} ${textColor}`;
    if (rounded) sectionClass += ' rounded';

    let style = {
        width: `${dimens.width}%`,
        left: `${dimens.start}%`,
    };

    return (
        <div className={sectionClass} style={style}>
            {textLeft &&
            (<div className={`timezone-day-section-text left section-text-${textLeft.style}`}>{textLeft.text}</div>)}
            {textCenter &&
            (<div className={`timezone-day-section-text center section-text-${textCenter.style}`}>{textCenter.text}</div>)}
            {textRight &&
            (<div className={`timezone-day-section-text right section-text-${textRight.style}`}>{textRight.text}</div>)}
        </div>
    )
}
