import ct from 'countries-and-timezones';

export const API_KEY = 'AIzaSyD2xNoTRyVMEJFGQn0C22JHzCtUdKmqitk';

export function loadScript(src, position, id) {
    if (!position) {
        return;
    }

    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

export function loadGoogleMaps(loaded) {
    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(
                `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places`,
                document.querySelector('head'),
                'google-maps',
            );
        }

        loaded.current = true;
    }
}

export const getTimezoneForLocation = (lat, lng) => {
    let targetDate = new Date();
    let timestamp = targetDate.getTime() / 1000 + targetDate.getTimezoneOffset() * 60;
    return fetch(`https://maps.googleapis.com/maps/api/timezone/json?location=${lat},${lng}&timestamp=${timestamp}&key=${API_KEY}`)
        .then((response) => {
            return response.json();
        })
};

export function getCountryFromPlace(place) {
    let countryName;
    if (place.includes(',')) {
        let parts = place.split(',');
        countryName = parts[parts.length - 1].trim()
    } else {
        countryName = place.trim()
    }
    return Object.values(ct.getAllCountries()).find(country => country.name.toLowerCase() === countryName.toLowerCase());
}
